/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { shape, string } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReactMarkdown from 'react-markdown';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import CountDownTimer from './CountDownTimer';
import CTA from '../../../ContentTypeComponents/Buttons/CTA';
import cleanText from '../../../../helpers/contentstack/cleanText';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import parseFontFamilyExtension from '../../../../helpers/contentstack/parseFontFamilyExtension';
import useUTMRules from '../../../../helpers/DynamicRules/hooks/useUTMRules';
import SeeDetailLink from '../SeeDetailLink/SeeDetailLink';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import StyledButton from '../../../EnterpriseDesignSystem/Button/StyledButton';

const useStyles = makeStyles((theme) => ({
    banner: {
        fontFamily: 'inherit',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        position: 'relative',
        backgroundPosition: 'left 150px bottom 0px',
        '@media screen and (min-width: 1024px) and (max-width: 1366px)': {
            backgroundPosition: 'left 85px bottom 0px',
        },
        '@media screen and (min-width: 768px) and (max-width: 1023px)': {
            backgroundPosition: 'left 20px bottom 0px',
        },
        [theme.breakpoints.down(768)]: {
            backgroundPosition: 'left 10px top 0px',
        },
        [theme.breakpoints.down(441)]: {
            backgroundPosition: 'right 10px top 23px',
        },
        [theme.breakpoints.down(340)]: {
            backgroundPosition: 'right 0px top 25px',
        },
    },
    linkContainer: {
        position: 'relative',
        width: '100%',
        '& a': {
            textDecoration: 'none',
        },
    },
    messageContainer: {
        width: '100%',
        border: 0,
        padding: 0,
        cursor: 'pointer',
    },
    bannerHeading: {
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        lineHeight: '28px',
        marginRight: '2.5rem',
        color: theme.palette.cms?.white || '#ffffff',
        padding: '0',
        '& p': {
            padding: 0,
            margin: 0,
        },
        [theme.breakpoints.down(768)]: {
            fontSize: '16px',
            lineHeight: '20px',
            margin: '0px 24px 0px 0px',
        },
    },

    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        fontSize: '18px',
        '@media screen and (min-width: 680px) and (max-width: 1023px)': {
            fontSize: '16px',
            height: '49px',
        },
        [theme.breakpoints.down(680)]: {
            fontSize: '14px',
            flexDirection: 'column',
            height: '83px',
            gap: '8px',
        },
        [theme.breakpoints.down(441)]: {
            alignItems: 'center',
            paddingLeft: '0px',
        },
    },
    ctaContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '0',
        marginLeft: '2rem',
        width: '100%',
        [theme.breakpoints.down(768)]: {
            marginLeft: '2rem',
        },
        [theme.breakpoints.down(480)]: {
            marginLeft: '0.5em',
        },
        '& a': {
            width: 'max-content',
        },
    },
    viewBtn: {
        '& div': {
            border: 'none',
            fontSize: '16px',
            fontFamily: 'LatoBold',
            marginTop: 0,
            textTransform: 'uppercase',
            alignItems: 'center',
            color: 'var(--comp-button-color-action-fg-secondary-default, #2F2F2F)',
            '& .MuiTouchRipple-child': {
                background: 'transparent',
            },
        },
        '& svg': {
            fontSize: '24px',
            paddingTop: '0',
        },
    },
    hideBanner: {
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '20px',
        padding: '0 10px',
        bottom: '0',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& svg': {
            width: '14px',
            height: '14px',
            fill: theme.palette.cms?.white || '#ffffff',
        },
        '@media screen and (min-width: 1024px) and (max-width: 1366px)': {
            right: '13px',
        },
        [theme.breakpoints.down(441)]: {
            top: '10px',
            right: '10px',
            bottom: 'auto',
        },
    },
    timeContainer: {
        display: 'flex',
    },
    bannerWrapper: {
        '& $innerContainer': {
            [theme.breakpoints.down(441)]: {
                alignItems: 'flex-start',
                paddingLeft: '5px',
            },
        },
        '& $bannerHeading': {
            [theme.breakpoints.down(768)]: {
                margin: '8px 0px 0px 8px',
            },
        },
    },
    bannerFullWidth: {
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
    },
    clickable: {
        cursor: 'pointer',
    },
}));

const CountDownBanner = ({ data }) => {
    const utmPassed = useUTMRules(data);
    if (!utmPassed || !data) {
        return <></>;
    }
    const cta = data.cta?.link?.href?.length ? data.cta : '';
    const dispatch = useDispatch();
    const [isShowBanner, setShowBanner] = useState(true);
    const countDownDate = new Date(data?.end_date).getTime();
    const ffIsEdsButtonEnabled = useSelector(getFeatureFlag('is-eds-button-enabled'));
    const nowTime = new Date().getTime();
    const cookie = new Cookies();
    const cookieKey = `top_banner_${data.headline}`;
    const cookieValue = cookie.get(cookieKey);
    const renderCountdown =  nowTime < countDownDate;
    const bannerClickable =  data?.banner_clickable || false;
    const promoCode = data?.promo_code_details;
    const isSmallerDevice = useMediaQuery('(max-width: 679px)');

    const history = useHistory();
    const styles = data.styling;

    if (styles?.background_graphic?.url) {
        styles.backgroundGraphic =  `url("${styles?.background_graphic?.url}")`;
    }
    const classes = useStyles(styles);
    if (!renderCountdown || cookieValue) { return null; }

    const tracking = (e) => {
        dispatch(trackEvent({
            eventCategory: data.cta?.tracking_event_category || '',
            eventAction: data.cta?.tracking_event_action || '',
            eventLabel: data.cta?.tracking_event_label || '',
        }));
        if ((cta.link.href.includes('https://') || cta.link.href.includes('http://')) && typeof window !== 'undefined') {
            e.preventDefault();
            window.open(cta.link.href, '_self');
        } else history.push(cta.link.href);
    };

    const hideBanner = () => {
        setShowBanner(false);
        // set a cookie to expire after 1 days
        const expDate = new Date(nowTime + (1 * 24 * 60 * 60 * 1000));
        cookie.set(cookieKey, true, {
            expires: expDate,
        });
    };

    const renderBanner = () => (
        <div
            className={`${classes.banner} ${styles?.full_width ? classes.bannerFullWidth : ''} ${styles?.backgroundGraphic ? classes.bannerWrapper : ''} ${bannerClickable ? classes.clickable : ''}`}
            style={{
                backgroundImage: styles?.backgroundGraphic,
                backgroundColor: styles?.background_color?.color || '#000',
            }}
        >
            <div className={classes.innerContainer}>
                {data?.headline && (
                    <div
                        className={`${classes.bannerHeading}`}
                        style={{
                            color: styles?.font_color?.color,
                            fontFamily: parseFontFamilyExtension(styles?.font),
                        }}
                    >
                        <ReactMarkdown source={cleanText(data.headline)} escapeHtml={false} />
                    </div>
                )}
                <div className={`${classes.timeContainer}`}>
                    <CountDownTimer dateCountDown={data.end_date} styling={styles} hideBanner={hideBanner} />
                    {cta && data?.has_cta && !isSmallerDevice
                     && (
                         ffIsEdsButtonEnabled
                             ? (
                                 <div className={classes.ctaContainer}>
                                     <StyledButton label={cta?.link?.title || 'Click Here'} isInsideClickableLink={bannerClickable} link={cta?.link?.href} handleOnClick={tracking} size="sm" variant="secondary"  attributes={{ 'aria-labelledby': `countDown banner ${cta?.link?.title.split(' ').join(' ').toLowerCase()}` }} />
                                 </div>
                             ) : (
                                 <div className={classes.ctaContainer}>
                                     <CTA
                                         href={cta.link.href}
                                         copy={cta.link.title || 'Click Here'}
                                         fontColor={styles.font_color?.color}
                                         className={classes.viewBtn}
                                         fontFamily={styles?.font}
                                         tracking_event_category={cta.tracking_event_category}
                                         tracking_event_action={cta.tracking_event_action}
                                         tracking_event_label={cta.tracking_event_label}
                                     />
                                 </div>
                             )
                     )}
                    {
                        data?.see_details && promoCode && (
                            <SeeDetailLink promoCode={promoCode} />
                        )
                    }
                </div>
            </div>
            {!bannerClickable &&  (
                <IconButton className={classes.hideBanner} aria-label="close" onClick={() => hideBanner()}>
                    <CloseIcon style={{ fill: styles?.font_color?.color }} />
                </IconButton>
            )}
        </div>
    );

    return (
        isShowBanner && (
            bannerClickable
                ? (
                    <div className={classes.linkContainer}>
                        <button
                            type="button"
                            data-ga-category={cta.tracking_event_category || ''}
                            data-ga-action={cta.tracking_event_action || ''}
                            data-ga-label={cta.tracking_event_label || ''}
                            onClick={(e) => {
                                tracking(e);
                            }}
                            title={cta.link.title || 'Click Here'}
                            className={classes.messageContainer}
                        >
                            {renderBanner()}
                        </button>
                        <IconButton className={classes.hideBanner} aria-label="close" onClick={() => hideBanner()}>
                            <CloseIcon style={{ fill: styles?.font_color?.color }} />
                        </IconButton>
                    </div>
                )
                : renderBanner()
        )
    );
};
CountDownBanner.propTypes = {
    data: shape({
        banner_header: shape({
            heading: string,
            subheading: string,
        }),
    }).isRequired,
};

export default CountDownBanner;
